/*!
 * Main
 *
 * {{name}}
 * {{description}}
 * @author {{author}}
 * @version {{version}} ({{hash}})
 * {{copyright}}
 */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'isMobile': false,
		'common': {
			init: function () {
				Sage.isMobile = $(window).width() < 600;

				$('body').addClass('ready');

				initDropdowns();
				initWebsiteSearch();
				initVideos();
				initSidebarSubmenu();
				initInterpreterSearch();
				initAccordion();
				initSectionBackgroundColor();
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
				if (!Sage.isMobile) {
					initFancyLinks();
				}
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'template_internal_profile': {
			init: function() {
				// $( 'body' ).on( 'keyup', '.acf-field[data-name="password"] input[type="password"], .acf-field[data-name="password_repeat"] input[type="password"]',
				// 	function( event ) {
				// 		if(typeof wp.passwordStrength !== 'undefined') {
				// 			checkPasswordStrength(
				// 				$('.acf-field[data-name="password"] input[type="password"]'),         // First password field
				// 				$('.acf-field[data-name="password_repeat"] input[type="password"]'), // Second password field
				// 				$('#password-strength'),           									// Strength meter
				// 				$('#user_password input[type=submit]'),           					// Submit button
				// 				['black', 'listed', 'word']        								// Blacklisted words
				// 			);
				// 		}
				// 	}
				// );

				// Prevent selection of more than 9 fields of expertise
				$('[data-name="field_of_expertise"] [type="checkbox"]').on('click', function(){
					var checkedCount = $('[data-name="field_of_expertise"] [type="checkbox"]:checked').length;
					if (checkedCount > 9) {
						return false;
					}
				});

				// Enhancement for profile_website_url_published field
				// Remove the true_false switch if the ACF is disabled, it will be hidden via CSS, but its probably better to remove it completely
				if (typeof acf !== 'undefined') {
					acf.addAction('load_field/name=profile_website_url_published', function (field) {
						if(field.$el.hasClass('disabled')) {
							field.$el.find('.acf-switch').remove();
						}
					});
				}
			}
		},
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	function initDropdowns() {
		var chosenSelects = $('.searchbar select, .form-filter select');
		var threshold = 5;
		var is_disabled = false;
		// Disable on iPad as well
		if (/iP(ad|od|hone)/i.test(window.navigator.userAgent)) {
			is_disabled = true;
		}

		if (chosenSelects.length > 0 && !is_disabled) {
			chosenSelects.chosen({
				no_results_text: AiicThemeTranslations.no_matching_result,
				width: '100%',
				inherit_select_classes: true,
				search_contains: true
			});

			// Hide the search input on single selects if there are n or fewer options.
			// We need to do this manually to add a custom class
			chosenSelects.each(function (index, select) {
				if ($(select).find('option').length <= threshold) {
					$(select).next('.chosen-container').toggleClass('no-search-input');
				}

				// add class for styling the filters when there are active
				var selected = $(select).find('option:selected');
				if ($(selected).length > 0 && $(selected).val() !== 'all' && $(selected).val() !== '') {
					$(select).next('.chosen-container').addClass('is-active');
				}
			});
		}

		// Mobile Fallback
		// chosen is disabled for iOS, Android (https://github.com/harvesthq/chosen/pull/1388)
		// therefore we add the placeholder-text to the first option
		if (chosenSelects.next('.chosen-container').length === 0) {
			chosenSelects.each(function (id, select) {
				var option = $(select).data('placeholder');
				if (option) {
					$(select).find('option').first().text(option);
				}
			});
		}
	}

	function initWebsiteSearch() {
		var searchButton = $('.nav-utility a[href="#search"]');
		var searchForm = $('header.banner .search-form');
		var searchResetButton = $('.search-reset');

		searchButton.on('click', function (event) {
			searchForm.toggleClass('active');
			searchForm.find('input[type="search"]').focus();
			event.preventDefault();
		});

		searchResetButton.on('click', function () {
			searchForm.removeClass('active');
			$('.search-field').attr('value', '');
		});
	}

	function initVideos() {
		$('.video-splash').on('click', function (event) {
			var video = '<iframe src="' + $(this).attr('href') + '"></iframe>';
			$(this).replaceWith(video);
			event.preventDefault();
		});
	}

	function initSidebarSubmenu() {
		if ($('.sidebar').length > 0) {
			$('.sidebar .menu-item-has-children > a').on('click', function (event) {
				$(this).parent().toggleClass('current-menu-ancestor');
				event.preventDefault();
			});
		}
	}

	function initInterpreterSearch() {
		$('input[name="bidirectional"]').on('change', function (event) {
			$(this).closest('form').find('.icon-direction').toggleClass('is-onedir');
		});

		$('.icon-direction').on('click', function (event) {
			var from = $('.fieldset-from select');
			var into = $('.fieldset-into select');
			var fromCurrentOption = from.find('option:selected').val();
			var intoCurrentOption = into.find('option:selected').val();

			from.val(intoCurrentOption).trigger('chosen:updated');
			into.val(fromCurrentOption).trigger('chosen:updated');

			$(this).toggleClass('is-reversed');
		});

		$('.form-isearch input[type="submit"]').on('click', function (event) {
			if (_validateInterpreterSearch()) {
				$('.isearch-error').show();
				event.preventDefault();
			}
		});

		// do it once
		if ($('.form-isearch').hasClass('js-isearch-is-active') && _validateInterpreterSearch()) {
			$('.isearch-error').show();
		}

		// Scroll to Results
		if ($('.form-isearch').hasClass('js-isearch-is-active') && !_validateInterpreterSearch() && $('.results-isearch').length) {
			var top = $('.results-isearch').offset().top;
			var offset = -200;
			$('html, body').animate({ scrollTop: top + offset }, 600);
		}

		// Validate Name Search
		$('form.search-name input[type="submit"]').on('click', function () {
			var searchField = $('.search-name .search-field');
			var form = $('form.search-name');

			searchField.removeClass('error').blur();
			form.next('.results').remove();

			if (!_isNameSearchValid()) {
				searchField.addClass('error');
				event.preventDefault();
			} else {
				form.addClass('is-waiting');
			}
		});
	}

	function initAccordion() {
		function _showFaq(collapsible) {
			if (collapsible.length) {
				$(collapsible).collapse('show');
			}
		}

		if ($('#accordion-faq').length) {
			// inital page load
			var hash = location.hash;
			if (hash) {
				var collapsible = $('#accordion-faq').find(hash);
				_showFaq(collapsible);
			}

			$(window).on('hashchange', function (event) {
				var hashChangedTo = location.hash;
				var collapsible = $('#accordion-faq').find(hashChangedTo);
				_showFaq(collapsible);
			});

			$('#accordion-faq .panel-collapse').on('shown.bs.collapse', function (event) {
				var activeId = $(event.currentTarget).attr('id');

				if (history.pushState) {
					history.pushState(null, null, '#' + activeId);
				}
				else {
					location.hash = activeId;
				}
			});
		}
	}

	function _validateInterpreterSearch() {
		var searchForm = $('.form-isearch');
		var selectedFrom = searchForm.find('select[name="from"]').find('option:selected');
		var selectedInto = searchForm.find('select[name="into"]').find('option:selected');
		var selectedCity = searchForm.find('select[name="city"]').find('option:selected');

		return (selectedFrom.val() === '' && selectedInto.val() === '' && selectedCity.val() === '');
	}

	function _isNameSearchValid() {
		var searchField = $('.search-name .search-field').val();
		if (searchField.length < 3) {
			return false;
		} else {
			return true;
		}
	}

	function initFancyLinks() {
		if ($('.linkgrid').length) {
			var $links = $('.linkgrid').find('li');
			var count = $links.length;

			var intervalID = window.setInterval(function () {
				var random = _getRandomIntInclusive(0, count - 1);

				$links.find('span').removeClass('reveal-text');
				$links.eq(random).find('span:last-child').addClass('reveal-text');

			}, 3000);
		}
	}

	function _getRandomIntInclusive(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	function initSectionBackgroundColor() {
		if ($('body').hasClass('home')) {
			var count = $('main').children().length;
			if(count % 2 === 0) {
				$('footer.content-info').addClass('even-background');
			}
		}
	}

	function checkPasswordStrength( $pass1,	$pass2,	$strengthResult, $submitButton,	blacklistArray ) {
		var pass1 = $pass1.val();
		var pass2 = $pass2.val();

		// Reset the form & meter
		$submitButton.attr( 'disabled', 'disabled' );
		$strengthResult.removeClass( 'short bad good strong' );

		// Extend our blacklist array with those from the inputs & site data
		blacklistArray = blacklistArray.concat( wp.passwordStrength.userInputBlacklist() );

		// Get the password strength
		var strength = wp.passwordStrength.meter( pass1, blacklistArray, pass2 );

		// Add the strength meter results
		switch ( strength ) {
			case 2:
			$strengthResult.addClass( 'bad' ).html( pwsL10n.bad );
			break;

			case 3:
			$strengthResult.addClass( 'good' ).html( pwsL10n.good );
			break;

			case 4:
			$strengthResult.addClass( 'strong' ).html( pwsL10n.strong );
			break;

			case 5:
			$strengthResult.addClass( 'short' ).html( pwsL10n.mismatch );
			break;

			default:
			$strengthResult.addClass( 'short' ).html( pwsL10n.short );

		}

		// The meter function returns a result even if pass2 is empty,
		// enable only the submit button if the password is strong and
		// both passwords are filled up
		if ( 4 === strength && '' !== pass2.trim() ) {
			$submitButton.removeAttr( 'disabled' );
		}

		return strength;
	}

})(jQuery); // Fully reference jQuery after this point.
